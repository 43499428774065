/* eslint-disable @nx/enforce-module-boundaries */
import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, ROUTES, Routes } from '@angular/router';
import { HeaderData } from '@dmv/shared/ui';
import { LOGIN_REDIRECT_CONFIGURATION } from '@dmv/shared/utils-authentication';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { EnvironmentRoutingService } from './services';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    path: 'maintenance',
  },
  { component: PaymentConfirmationComponent, path: 'payment-token' },
  { component: OktaCallbackComponent, path: 'callback' },
  { component: QrCodeComponent, path: 'qr-code' },
  {
    loadChildren: () => import('./qr-code/qr-code.module').then(m => m.QrCodeModule),
    path: 'qr-code/:reservationId',
  },
  {
    loadChildren: () => import('./barcode/barcode.module').then(m => m.BarcodeModule),
    path: 'barcode/:reservationId',
  },
  {
    loadChildren: () => import('./qr-code/qr-code.module').then(m => m.QrCodeModule),
    path: 'qr-code/:reservationId/:application/:transactionId',
  },
  {
    loadChildren: () => import('./barcode/barcode.module').then(m => m.BarcodeModule),
    path: 'barcode/:reservationId/:application/:transactionId',
  },
  {
    canActivate: [OktaAuthGuard],
    loadChildren: () => import('./pay/payment-redirect.module').then(m => m.PaymentRedirectModule),
    path: 'pay',
  },
  {
    canActivate: [OktaAuthGuard],
    loadChildren: () => import('./temp-inspection/temporary-inspection.module').then(m => m.TemporaryInspectionModule),
    path: 'registration/:registrationId/temporary-inspection',
  },
  {
    canActivate: [OktaAuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    path: 'home',
  },
  {
    data: {
      header: {
        title: 'NYS DMV Digital Intake',
      } as HeaderData,
      title: `NY DMV | Digital Intake`,
    },
    loadChildren: () => import('@dmv/shared/utils-authentication').then(m => m.LoginRedirectModule),
    path: 'login',
  },
  {
    canActivate: [externalUrlProvider],
    // Need a component here because we cannot define the route otherwise
    component: NotFoundComponent,
    path: 'externalRedirect',
  },
];

// TODO Once application checklist is implemented and accepted, revert this back to just another path with a lazy loaded module.
const routesFactory = () => {
  return [
    ...routes,
    {
      /*
       * Any path that doesn't match the above config will match this wildcard, so it must be the last one defined.
       * The unmatched URL will truncate and display only the app.component.html with an empty router-outlet.
       *
       * Child modules may declare their own wildcard path to prevent matching this one.
       * Examples:
       *   original-permit-feature-shell.module.ts  -> /home/original-permit/**
       *   real-id-edl-upgrade.module.ts            -> /home/real-id-edl-upgrade/**
       */
      path: '**',
      redirectTo: '/home',
    },
  ];
};

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot([], {})],
  providers: [
    EnvironmentRoutingService,
    {
      deps: [],
      multi: true,
      provide: ROUTES,
      useFactory: routesFactory,
    },
    {
      deps: [EnvironmentRoutingService],
      provide: LOGIN_REDIRECT_CONFIGURATION,
      useFactory: (env: EnvironmentRoutingService) => env.getRedirectUrl(),
    },
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_self');
      },
    },
  ],
})
export class AppRoutingModule {}
